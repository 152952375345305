<template>
  <section class="relative">
    <video ref="videoRef"
           autoplay
           playsinline
           muted
           loop
           class="object-cover w-full h-full"
    >
      <source :src="videoSource"
              type="video/mp4"
      >
    </video>

    <button v-show="!videoFullScreen && !hidePlayButton"
            class="absolute inset-0 z-10 flex items-center justify-center"
            @click="handleClick"
    >
      <div class="inline-flex items-center justify-center w-12 h-12 text-white rounded-full md:w-32 md:h-32 bg-black/60">
        <span class="sr-only">Play</span>
        <IconPlay class="w-4 h-4 text-white md:w-8 md:h-8" />
      </div>
    </button>
  </section>
</template>

<script setup lang="ts">
// The video is being played in the background and the button is only visible when the video is muted.
// If the user clicks the button, it puts the video in fullscreen and unmutes it
// Once the video gets out of the fullscreen it's paused. Clicking on it again
// resumes the video.
import { onMounted, onUnmounted, ref } from 'vue'

interface VideoProps {
  videoSource: string
  hidePlayButton: boolean
}

withDefaults(defineProps<VideoProps>(), {
  hidePlayButton: false
})

const videoRef = ref(null)
const videoFullScreen = ref(false)

onMounted(() => {
  window.addEventListener('fullscreenchange', handleFullScreenChange)
  window.addEventListener('webkitfullscreenchange', webkitHandleFullScreenChange)
})

onUnmounted(() => {
  window.removeEventListener('fullscreenchange', handleFullScreenChange)
  window.removeEventListener('webkitfullscreenchange', webkitHandleFullScreenChange)
})

/**
 * Handle play button click
 *
 * Should open the video in fullscreen
 * Based on the browser, we set the video element to fullscreen
 */
function handleClick() {
  if (!videoRef.value) return

  // Toggle fullscreen
  videoFullScreen.value = true

  // if video is not paused, set the current time to 0
  if (!videoRef.value.paused) {
    videoRef.value.currentTime = 0
  }

  if (videoRef.value.requestFullscreen) {
    videoRef.value.requestFullscreen()
  }
  else if (videoRef.value.webkitRequestFullscreen) {
    /* Safari */
    videoRef.value.webkitRequestFullscreen()
  }
  else if (videoRef.value.msRequestFullscreen) {
    /* IE11 */
    videoRef.value.msRequestFullscreen()
  }
  else {
    // We are on Safari iOS
    // There are no fulscreen options, we set some options and start playing
    // this opens the video on fullscreen
    videoRef.value.pause()
    videoRef.value.playsInline = false
  }

  videoRef.value.controls = true
  videoRef.value.muted = false
  videoRef.value.play()
}

function handleFullScreenChange() {
  if (document.fullscreenElement) {
    videoFullScreen.value = true
  }
  else {
    videoRef.value.pause()
    videoRef.value.muted = true
    videoFullScreen.value = false
  }
}

/**
 * Safari has non standard full screen elements…
 */
function webkitHandleFullScreenChange() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (document.webkitCurrentFullScreenElement) {
    videoFullScreen.value = true
  }
  else {
    videoRef.value.pause()
    videoRef.value.muted = true
    videoFullScreen.value = false
  }
}
</script>
